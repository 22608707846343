import axios from 'axios'
import { Message } from 'element-ui'

const baseURL = process.env.NODE_ENV === 'production'?'':'/api' // 导入 环境变量中的 基地址
// const instance = axios.create({
//   // TODO 1:设置基地址,请求时间
//   baseURL: baseURL,
//   timeout: 10000
// })

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    const token = document.cookie.split('=')[1] || ''
    if (token) {
      config.headers['plat'] = 'pc'
      config.headers['token'] = token
      config.headers['uid'] = `${user.uid}`
    }
    return config
  },
  (err) => Promise.reject(err)
)
// 响应拦截器,剥离无效的数据,401拦截并跳登录页,同时清空用户信息
axios.interceptors.response.use(
  (res) => {
    if (res.data.retCode !== 200) {
      Message.error('数据异常')
      return Promise.reject(res.data)
    } else if (res.data.code === 1) {
      return res.data
    } else {
      Message.warning(res.data.msg)
      return Promise.reject(res.data)
    }
  },
  (err) => {
    if (err.message.includes('timeout')) Message.error('请求超时!')
    else Promise.reject(err )
  }
)

export function request(url, params = {}, isFile = false, headers={}) {
  let data = '';
  if (isFile) {
    //params参数中含有文件上传的参数
    let formObj = new FormData()
    for (let keys in params) {
      formObj.append(keys, params[keys])
    }
    data = formObj;
    headers = {
      'Content-Type':"multipart/form-data"
    }
    return axios.post(`${baseURL}${url}`, params, headers)
  } else {
    return axios.post(`${baseURL}${url}`, params)
  }
}
