import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
// import { Message } from "element-ui";
const baseURL = process.env.NODE_ENV === 'production'?'':'/zt14'

//请求拦截器：对所有请求数据作统一处理
axios.interceptors.request.use((config) => {
  if(config.method == 'post'){
    if(config.headers['Content-Type'] == 'application/json;charset=UTF-8'){
      // 如果不是 FormData(文件上传)类型\
      if(!(config.data instanceof FormData)){
        config.data = qs.stringify(config.data)
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }
    }
  };
  // 获取cookie
  let userInform = document.cookie;
  let token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ3d3cubGlhbmJhbmdpb3QuY24iLCJhdWQiOiJ3d3cubGlhbmJhbmdpb3QuY24iLCJpYXQiOjE3MTc1Nzk0NDMsIm5iZiI6MTcxNzU3OTQ0MywiZXhwIjoxNzIwMTcxNDQzLCJleHRlbmQiOnsiaWQiOjEsImNpZCI6MSwiZ2lkIjoxLCJtaWQiOjEsInBob25lIjoiMTU2MDMzNzkyNDYiLCJ0eXBlIjowLCJmaW5hdGltZSI6bnVsbCwiZmluYWxpcCI6bnVsbCwiY3RpbWUiOiIyMDI0LTA1LTMxIDE4OjA0OjIyIiwiY3VzZXIiOiJcdThmZGVcdTkwYTZcdThmNmZcdTRlZjZcdWZmMDhcdTRlMGFcdTZkNzdcdWZmMDlcdTY3MDlcdTk2NTBcdTUxNmNcdTUzZjgiLCJzdGF0dXMiOjEsInBvd2VyIjotMSwicG93ZXJfbWVudSI6Ii0xIiwiaGVhZCI6ImhlYWQucG5nIiwiZW1haWwiOiJlZWVlQDE2My5jb20iLCJnbG9nbyI6Imh0dHBzOi8vd3d3LnFpeGluLmNvbS9jb21wYW55LzgzODUxMWMxLTAzNjItNDczYi1hOTQ0LTM2ZWM3YWYzNjEzZSIsImdzbmFtZSI6Ilx1NTU0YVx1NGU2Nlx1NmNkNVx1NTkyN1x1OGQ1Ylx1NTNkMSIsInJlbWFyayI6Ilx1OGZkOVx1NjYyZlx1NGUwMFx1NGUyYVx1ODBjY1x1NGU2Nlx1NzIzMVx1Nzk1ZVx1NzY4NFx1N2JhZGFhYVx1NTczMFx1NjViOVx1NTU0YSIsInB1c2hpZCI6bnVsbCwiYWNjZXNzX2V4cCI6MjU5MjAwMH19.6av8MXE3nP09lbmaAl0UKcJsv-0-tIYzeo08ngLrhxQ'
  if(!userInform){
    token = ''
  }
  config.headers.Authorization = token;
  config.headers.appid = 'LB0000002406040001';
  config.headers.gid = 1;
  return config
})

//响应拦截器
axios.interceptors.response.use((res) => {
  if (res.data.code != 1) {
    // console.log(`${res.data.msg}`)
  }
  return res
})

// get请求  url:请求地址  params 请求参数 
export function sendGet(url, params = {}, headers = {}) {
  return axios.get(`${baseURL}${url}`, { params, headers })
}


//post()  url:请求地址  params:请求参数  isFile:是否包含文件类型
export function requestzt14(url, params = {}, isFile = false, headers={}) {
  let data = '';
  if (isFile) {
    //params参数中含有文件上传的参数
    let formObj = new FormData()
    for (let keys in params) {
      formObj.append(keys, params[keys])
    }
    data = formObj;
    headers = {
      'Content-Type':"multipart/form-data"
    }
  } else {
    //params参数中没有文件上传的参数
    data = qs.stringify(params);
    headers = {
      'Content-Type':'application/x-www-form-urlencoded'
    }
  }
  return axios.post(`${baseURL}${url}`, data, headers)
}
