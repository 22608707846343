import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
// import { Message } from "element-ui";
const baseURL = process.env.NODE_ENV === 'production'?'':'/api'

//请求拦截器：对所有请求数据作统一处理
axios.interceptors.request.use((config) => {
  if(config.method == 'post'){
    if(config.headers['Content-Type'] == 'application/json;charset=UTF-8'){
      // 如果不是 FormData(文件上传)类型\
      if(!(config.data instanceof FormData)){
        config.data = qs.stringify(config.data)
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }
    }
  };
  // 获取cookie
  let userInform = document.cookie;
  let token = '';
  const key = 'd6a5UxLk4mqqZjAKQIFrlZlWmvXZ4b7iIiZcrI8DfHHfz32OJYWFJgl+OtHDKm9v8mp6egJtL0KmkgqiN/BYUi3FB2du6r6Nz4yVkfw72ioXZA4+N8fkX8EFwqF2kqOp3Mj2Tn4VpIJsl8EpDJqu5hJj4T8ce0OeP2Fyk86ghTjrKhEjIY7Ax7JfkNbTcEIwjSWojJQR54qOQVEj0XCBG3hJJ8mHdi0+75b3tOWO'
  
  if(!userInform){
    token = ''
  }else{
    token =  userInform.split('=')[1]
  }
  config.headers.authorization = token;
  return config
})


//开发环境 8080
if (process.env.NODE_ENV === 'development') {
  Vue.prototype.imgHost = 'https://api.fediot.net'
}

//生产环境：将打包后的vue项目代码部署到服务端应用程序中
if (process.env.NODE_ENV === 'production') {
  Vue.prototype.imgHost = 'https://admin.lianbangiot.com'
}


//响应拦截器
axios.interceptors.response.use((res) => {
  if (res.data.code != 1) {
    console.log(`${res.data.msg}`)
  }
  return res
})

// get请求  url:请求地址  params 请求参数 
export function sendGet(url, params = {}, headers = {}) {
  return axios.get(`${baseURL}${url}`, { params, headers })
}


//post()  url:请求地址  params:请求参数  isFile:是否包含文件类型
export function sendPost(url, params = {}, isFile = false, headers={}) {
  let data = '';
  if (isFile) {
    //params参数中含有文件上传的参数
    let formObj = new FormData()
    for (let keys in params) {
      formObj.append(keys, params[keys])
    }
    data = formObj;
    headers = {
      'Content-Type':"multipart/form-data"
    }
  } else {
    //params参数中没有文件上传的参数
    data = qs.stringify(params);
    headers = {
      'Content-Type':'application/x-www-form-urlencoded'
    }
  }
  return axios.post(`${baseURL}${url}`, data, headers)
}
