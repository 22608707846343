import { sendPost } from './request.js'; 
import { request } from './requestapi.js'; 
import { requestzt14 } from './requestzt14.js'; 


//用户登录--发送验证码
export let loginCode = d => request('/api/admin/user/send/sms',d);

// 用户登录--后台登录--验证码
export let login = d =>request('/api/admin/user/login',d)


// 网关管理--开始

  // 录入网关
  export let inputGateWay  = d => sendPost('/admin/net/input',d);

  // 批量入库
  export let multShipping = (d,d2) =>sendPost('/admin/net/inpAll',d,d2)

  // 网关列表
  export let gateWayList = d => request('/api/admin/all/net/list',d);

  // 修改网关信息
  export let editGateWagInform = d => sendPost('/admin/net/info/up',d)

  // 删除网关信息
  export let delNetINform = d => sendPost('/admin/net/delete',d)

// 网关管理--结束

// 信息录入--开始

// 新建用户
export let createNewUser = d => request('/api/admin/contion/add',d);

//获取用户列表
export let getAllList = d => request('/api/admin/contion/list',d);

// 修改用户
export let editUser = d => request('/api/admin/contion/up',d)

// 获取所有用户详情
export let getUserDetail = d => sendPost('/admin/contion/info',d);
// 获取单个用户的详情
export let fetchSignUser = d => sendPost('/admin/contion/info',d)

// 获取设备列表
export let deviceList = d => request('/api/admin/mach/all/ist',d);
// 网关下设备列表
export let gateWayDeviceList = d => request('/api/user/mach/list',d);
// 获取设备详情
export let deviceDetail = d => request('/api/elen/user/mach/info',d)

// 信息录入--结束


// 代理商接口--开始

// 代理商增加
export let addAngent = (d) => request('/api/admin/agent/user/add',d);

// 代理商列表
export let angentList  = d => request('/api/admin/agent/list',d);

// 代理商删除 
export let agentDel  = d => request('/api/admin/agent/del/by/id',d);

// 更新代理商信息
export let agentUp  = d => request('/api/admin/agent/info/update',d);

// 代理商的合同上传和证件上传
export let fileUpload = (d,d2) => sendPost('/api/upload/annex/tract/up',d,d2)

// 产品信息
export let addProducts = (d,d2) => sendPost('/admin/duct/add',d,d2);

// 产品信息列表
export let productList = d => sendPost('/admin/duct/info/list',d);

// 代理商接口--结束


//消息中心 -列表
export let getNewlist = (d) => sendPost("/admin/server/msg/list", d);

//查看
export let lookNewlist = (d) => sendPost("/admin/server/msg/get", d);

//发布(添加)
export let publish = (d) => sendPost("/admin/server/msg/add", d);

//删除
export let singleDelete = (d) => sendPost("/admin/server/msg/del", d);

//报警信息   离线设备
export let offequipment = (d) => sendPost("/api/user/log/offline/list", d);

// 报警列表
export let alarmList = (d) => request("/api/jing/list", d);

//系统设置  ---  
//日志管理
export let logManagement = (d) => request("/api/admin/user/admin/log", d);

//--------------账户管理
//账户管理列表
export let accountManagementList = (d) => request("/api/admin/contion/list", d);

//app列表
export let appList = (d) => requestzt14("/apis/app/list", d);
//app更新版本
export let appUpdate = (d) => requestzt14("/apis/app/version/up", d,  true, {headers:{'Content-Type': 'multipart/form-data'}});
// app删除
export let appDel = (d) => requestzt14("/apis/app/status/change", d);

export const downloadApi = (d) => requestzt14('/apis/app/info/list', d)


